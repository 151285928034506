.container {
  flex-wrap: wrap;
  display: flex;
  gap: rem(20);

  .card {
    width: 100%;
  }

  &.wideContainer {
    @media bp(sm) {
      .card {
        width: calc(calc(100% - rem(20)) / 2);
      }
    }

    @media bp(md) {
      .card {
        width: calc(calc(100% - rem(40)) / 3);
      }
    }
  }
}

.containerHollow {
  @media bp(sm) {
    justify-content: center;
  }
}
