.container {
  display: grid;
  list-style: none;
  row-gap: var(--gap);
  transition: trn(height);
  height: calc(var(--first-height) + var(--bottom));
  overflow: hidden;
  contain: content;
  padding-left: rem(15);
  padding-right: rem(15);
  padding-bottom: var(--bottom);

  --gap: rem(16);
  --bottom: rem(50);

  @media bp(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.expanded {
  height: var(--container-height);
}

.card {
  position: relative;
  transition: trn(opacity, transform, filter);
  transform-origin: bottom center;
  isolation: isolate;

  &::before {
    box-shadow: 0 rem(7) rem(30) rem(-10) clr(b/25);
    background-color: clr(w);
    border: bdr(1, p-70);
    border-radius: rem(12);
    transition: trn(border-color);
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.disabledCard {
  --shift: calc(var(--top-shift) + calc(var(--index) * var(--gap)));
  --scale-x: max(calc(1 - calc(var(--index) * 0.07)), 0.1);
  --scale-y: max(calc(1 - calc(var(--index) * 0.21)), 0.1);
  --c: calc(calc(var(--shift) - var(--first-height)) - calc(var(--index) * rem(10)));

  pointer-events: none;
  filter: blur(rem(1));
  transform: scale(var(--scale-x), var(--scale-y)) translateY(calc(calc(-1 * var(--c)) / var(--scale-y)));

  &::before {
    border-color: clr(w);
  }
}

.fadedOutCard {
  opacity: 0;
}

.button {
  position: absolute;
  height: rem(30);
  width: rem(30);
  background: clr(b/4);
  border-radius: rem(8);
  display: flex;
  align-items: center;
  justify-content: center;
  right: rem(10);
  top: rem(10);
  transform: none;

  @media bp(lg) {
    right: rem(30);
    top: 50%;
    transform: translateY(-50%);
    height: rem(44);
    width: rem(44);
  }
}

.buttonIcon {
  width: rem(12);
  height: rem(7);
  transition: trn(transform);
}

.expandedButtonIcon {
  transform: rotate(180deg);
}
