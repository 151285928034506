.container {
  display: flex;
  height: rem(44);
  padding-left: rem(17);
  justify-content: space-between;
}

.icon {
  width: rem(16);
  height: rem(16);
  flex-shrink: 0;
  padding: rem(14);
  box-sizing: content-box;
  background-color: clr(b/10);
}
