.container {
  position: relative;
  margin: rem(20) 0;
  font-size: rem(30);
  line-height: 1.25;
  font-weight: bold;
  padding-top: rem(10);

  &::before {
    content: '';
    width: rem(60);
    height: rem(5);
    position: absolute;
    left: 0;
    top: 0;
    background-color: clr(p-70);
    border-radius: rem(2);
  }

  @media bp(sm) {
    font-size: rem(32);
    padding-top: rem(20);
  }
}
