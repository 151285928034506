.container {
  contain: content;
  padding: rem(11) rem(20);
  background-color: clr(g-90);
  color: clr(g-10);
  font-weight: 500;
  font-size: rem(12);
  line-height: rem(18);
  text-align: end;
}
