.container {
  display: grid;
  grid-template-columns: repeq(2);
  gap: rem(8) rem(15);
  line-height: 1;
}

.msrp,
.save {
  font-size: rem(10);
  letter-spacing: rem(1);
  font-weight: bold;
}

.msrp {
  color: clr(cb-btn-bg-primary);
}

.discount {
  color: clr(p-70);
}

.price,
.discount {
  font-size: rem(18);
  font-weight: bold;
}
