.linkedButtonsLazy {
  min-height: rem(50);
}

.postsListLargeLazy {
  min-height: rem(655);

  @media bp(md) {
    min-height: rem(670);
  }
}

.postsListSmallLazy {
  min-height: rem(1100);

  @media bp(md) {
    min-height: rem(1115);
  }
}

.postsListLazy {
  @media bp(lg) {
    min-height: rem(1165);
  }
}

.adLazy {
  min-height: rem(1);
}

.containerLazy {
  width: 100vw;
  margin-left: rem(-15);
  padding: 0 rem(15);

  @media bp(sm) {
    margin-left: 0;
    padding: 0;
    width: auto;
  }
}

.newsletterLazy {
  min-height: rem(310);

  @media bp(sm) {
    min-height: rem(210);
  }
}

.videoLazy {
  padding-top: 56.25%;
  aspect-ratio: 16 / 9;
}

.audioLazy {
  min-height: rem(80);

  @media bp(sm) {
    min-height: rem(40);
  }
}

.moreLazy {
  min-height: rem(332);

  @media bp(sm) {
    min-height: rem(258);
  }

  @media bp(md) {
    min-height: rem(278);
  }

  @media bp(lg) {
    min-height: rem(258);
  }

  @media bp(xl) {
    min-height: rem(278);
  }
}

.moreLazyHome {
  min-height: rem(293);

  @media bp(sm) {
    min-height: rem(213);
  }

  @media bp(md) {
    min-height: rem(233);
  }

  @media bp(lg) {
    min-height: rem(213);
  }

  @media bp(xl) {
    min-height: rem(233);
  }
}

.carouselLazy {
  --view: calc(calc(var(--container-width) / var(--w)) * var(--h));
  --thumb-w: calc(calc(var(--container-width) - rem(50)) / 6);
  --thumbs: calc(calc(var(--thumb-w) / 16) * 9);
  --complex: calc(calc(var(--thumbs) + var(--view)) + rem(10));

  min-height: var(--complex);
}

.carouselLazyTitled {
  --title: rem(34);

  @media bp(lg) {
    --title: rem(40);
  }

  min-height: calc(var(--complex) + var(--title));
}

.feedbackLazy {
  min-height: rem(137);

  @media bp(sm) {
    min-height: rem(94);
  }
}

.dealsSliderLazy {
  --down: rem(225);
  --image-width: calc(var(--container-width) - rem(2));
  --top: calc(calc(var(--image-width) / 16) * 9);

  min-height: calc(var(--down) + var(--top));

  @media bp(md) {
    --image-width: calc(calc(var(--container-width) - rem(24)) / 2);
  }

  @media bp(lg) {
    --down: rem(253);
  }
}

.faqFeedbackLazy {
  min-height: rem(50);
}

.tiktokEmbed {
  height: rem(720);
  display: flex;
  justify-content: center;
}
