.container {
  position: relative;
  display: flex;
  column-gap: rem(16);

  --color: clr(b);

  &:hover {
      --color: clr(p-60);
  }
}

.count {
  left: 0;
  top: rem(4);
  min-width: rem(16);
  height: rem(16);
  position: absolute;
  background-color: clr(p-40);
  color: clr(cw);
  font-size: rem(14);
  line-height: rem(16);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & ~ .body {
    padding-left: rem(24);
  }
}

.image {
  flex-shrink: 0;
  width: rem(90);
  height: rem(90);
  border-radius: rem(8);
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: rem(4);
  justify-content: space-between;
}

.title {
  color: var(--color);
  transition: trn(color);
  font-size: rem(16);
  font-style: normal;
  font-weight: 700;
  line-height: rem(24);
  letter-spacing: rem(-0.3);
}

.bottomLine {
  font-size: rem(12);
  font-style: normal;
  font-weight: 600;
  line-height: rem(20);
  letter-spacing: rem(-0.1);
  display: flex;
  align-items: center;
}

.dotted {
  color: clr(g-20);

  &:not(:last-child) {
    margin-right: rem(6);

    &::after {
      content: '·';
      margin-left: rem(8);
    }
  }
}
