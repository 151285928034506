.voted {
  pointer-events: none;
}

.showResults {
  .progress,
  .choicePercent {
    opacity: 0;
  }
}

.title {
  margin-bottom: rem(5);
  font-size: rem(24);
  line-height: rem(32);
  font-weight: bold;
}

.votes {
  font-weight: 500;
  margin-bottom: rem(10);
  color: clr(g-20);
}

.choicesGrid {
  display: grid;
  row-gap: rem(15);
}

.button {
  margin: rem(3);
  contain: content;
  font-size: rem(18);
  text-align: left;
  display: grid;
  background-color: clr(g-90);
  grid-template-rows: 1fr rem(2);
  grid-template-columns: 1fr rem(40);
  overflow: hidden;
  border-radius: rem(2);

  &:hover {
    .choiceText {
      color: clr(p-70);
    }

    .progressHolder {
      background-color: clr(p-70);
    }
  }
}

.buttonVoted {
  box-shadow: shadow(ring, p-70);
}

.choiceText {
  padding: rem(12) 0 rem(12) rem(15);
  justify-self: start;
  align-self: center;
  font-weight: 500;

  @mixin transition color;
}

.choicePercent {
  justify-self: end;
  align-self: center;
  padding-right: rem(15);
  font-weight: bold;

  @mixin transition opacity;
}

.progressHolder {
  grid-column: span 2;
  background-color: clr(b/10);
  height: 100%;

  @mixin transition background-color;
}

.progress {
  height: 100%;
  background-color: clr(b);

  @mixin transition opacity;
}

.votes,
.choicePercent {
  line-height: 1.21;
  font-size: rem(14);
}
