.title {
  display: flex;
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: rem(24);
  line-height: rem(32);

  @media bp(sm) {
    justify-content: space-between;
    column-gap: rem(12);
    font-size: rem(16);
    line-height: rem(20);
    padding: rem(13) rem(15);
    color: clr(w);
    background-color: clr(b);
  }
}

.titleButton {
  display: none;

  @media bp(lg) {
    display: flex;
    column-gap: rem(10);
    align-items: center;
  }
}

.buttonTilteIcon {
  position: relative;
  top: rem(1);
  transition: trn(transform);
  width: rem(12);
  height: rem(12);
}

.buttonTilteIconActive {
  transform: rotate(180deg);
}

.button {
  border: bdr(2, currentColor);
  border-radius: rem(2);
}

.buttonIcon {
  transition: trn(transform);
  width: rem(12);
  height: rem(12);
}

.buttonActive {
  .buttonIcon {
    transform: rotate(180deg);
  }
}

.nodesContainer {
  transition: trn(height);
  height: var(--nodes-scroll-height);
  margin: rem(10) 0;
  contain: content;
  overflow: hidden;

  @media bp(sm) {
    margin: rem(5) 0;
  }
}
