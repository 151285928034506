.content {
  display: grid;
  row-gap: rem(10);

  @media bp(sm) {
    row-gap: rem(15);
    padding: rem(30);
    border-radius: rem(10);
    border: bdr(1, g-70);
    background-color: clr(g-90);
  }
}

.title {
  font-weight: bold;
  font-size: rem(24);
  line-height: rem(32);
}

.description {
  font-size: rem(18);
  line-height: rem(28);
}

.priceLine {
  font-weight: bold;
  font-size: rem(16);
  line-height: rem(24);
}

.priceLabel {
  color: clr(p-70);
}

.buttons {
  display: grid;
  gap: rem(10);
  margin-bottom: rem(5);
}

.buttonsMultiply {
  @media bp(sm) {
    grid-template-columns: repeq(2);
  }
}

.button {
  height: rem(46);
}

.buttonInner {
  justify-content: space-between;
  column-gap: rem(10);
}

.buttonText {
  @mixin textEllipsis;
}

.buttonIcon {
  width: rem(16);
  height: rem(16);
  flex-shrink: 0;
}

.gallery {
  display: flex;
  column-gap: rem(10);
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  /* disabling scrollbars */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
  }
}

.picture {
  flex-shrink: 0;
  scroll-snap-align: center;
  min-width: rem(210);

  &:last-of-type {
    scroll-snap-align: end;
  }

  &:first-of-type {
    scroll-snap-align: start;
  }

  @media bp(sm) {
    flex: 1 0;
    min-width: auto;
  }
}

.video {
  aspect-ratio: 16 / 9;
  padding-top: 56.25%;

  @media bp(sm) {
    border-radius: rem(5);
  }
}

.player {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
