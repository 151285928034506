.container {
  padding: rem(16);
  border-radius: rem(16);
  display: flex;
  flex-direction: column;
  row-gap: rem(12);
  background-color: clr(w);
  border: bdr(1, g-85);
  max-width: rem(438);
  margin: 0 auto;
}

.top {
  display: flex;
  column-gap: rem(8);
  align-items: center;
}

.logo {
  width: rem(120);
  aspect-ratio: var(--iw) / var(--ih);
}

.logoImage {
  background-color: transparent;
}

.image {
  background-color: transparent;
}

.title {
  font-size: rem(14);
  font-weight: 600;
  letter-spacing: rem(-0.3);
  line-height: rem(20);
  color: clr(g-5);
}

/* Стили для блока достижений */
.achievements {
  display: grid;
  grid-template-columns: repeq(3);
}

.achievement {
  padding: rem(4) rem(8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: rem(8);
}

.achievementTitle {
  font-size: rem(12);
  line-height: rem(16);
  font-weight: 500;
  letter-spacing: rem(-0.1);
  color: clr(g-40);
}

.metric {
  color: clr(g-5);
  font-size: rem(24);
  line-height: rem(30);
  font-weight: 600;
  letter-spacing: rem(-0.5);
}

.desc {
  border-radius: rem(8);
  background-color: clr(p-70/10);
  padding: rem(12);
  display: flex;
  column-gap: rem(12);
}

.text {
  flex: 1;
  color: clr(g-5);
  font-size: rem(12);
  font-weight: 500;
  line-height: rem(16);

  em {
    font-style: normal;
    color: clr(p-70);
    text-decoration: underline;
  }
}

.textImage {
  width: rem(72);
}

.inverted {
  /* filter: invert(1) hue-rotate(180deg); */
  filter: brightness(5);
}
