.container {
}

.string {
}

.block {
}

.list {
  list-style: outside;
  padding-left: rem(14);
}

.listItem {
}
