.container {
  user-select: none;
  border-radius: rem(2);
  background-color: clr(cb/70);
  color: clr(cw);
  font-weight: 400;
  font-size: rem(10);
  padding: rem(4) rem(8);
  display: block;
}
