.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: rem(15) rem(10);

  --shadow-size: rem(4);

  @media bp(sm) {
    grid-template-columns: auto auto auto 1fr;
    align-items: center;
  }
}

.question {
  justify-self: center;
  grid-column: span 2;
  font-weight: bold;
  font-size: rem(20);
  line-height: rem(28);

  @media bp(sm) {
    grid-column: span 1;
    justify-self: start;
    margin-right: rem(10);
  }
}

.button {
  border-radius: 100%;
  transform: translateY(calc(-1 * var(--shadow-size)));
  position: relative;
  top: rem(2);
  color: clr(cw);

  @mixin transition box-shadow, transform;

  &:hover {
    transform: translateY(calc(-1 / 2 * var(--shadow-size)));
  }
}

.buttonNegative {
  justify-self: start;
  background-color: clr(r-50);
  box-shadow: 0 var(--shadow-size) 0 clr(r-5), inset shadow(tj, r-7/30), shadow(td, r-7/40);

  &:hover {
    box-shadow: 0 calc(var(--shadow-size) / 2) 0 clr(r-5), inset shadow(t, transparent),
      shadow(te, r-7/40);
  }
}

.buttonPositive {
  justify-self: end;
  background-color: clr(p-70);
  box-shadow: 0 var(--shadow-size) 0 clr(p-30), inset shadow(tj, p-40/30), shadow(td, p-40/40);

  &:hover {
    box-shadow: 0 calc(var(--shadow-size) / 2) 0 clr(p-30), inset shadow(t, transparent),
      shadow(te, p-40/40);
  }
}

.icon {
  width: rem(20);
  height: rem(20);
  margin: rem(12);
  position: relative;
}

.iconNegative {
  top: rem(2);
  transform: rotate(180deg);
}

.iconPositive {
  position: relative;
  top: rem(-2);
}
