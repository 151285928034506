.container {
  display: grid;
  position: relative;
}

.widget {
  display: grid;
  row-gap: rem(10);
  padding: rem(15) rem(5) rem(10) rem(5);
  justify-items: stretch;

  @media bp(sm) {
    grid-template-columns: 1fr rem(270);
    grid-template-rows: auto auto;
    padding: rem(15);
  }
}

.info {
  display: grid;
  row-gap: rem(10);
  padding: 0 rem(10);

  @media bp(sm) {
    grid-row: span 2;
  }
}

.priceSave {
  margin-top: rem(5);
  padding: 0 rem(10);

  @media bp(sm) {
    margin-top: 0;
    align-self: end;
    padding: 0;
  }
}

.tags {
  font-size: rem(16);
  line-height: rem(24);
}

.score {
  color: clr(p-70);
}

.msrp {
  color: clr(b/50);
  font-size: rem(14);
  line-height: rem(20);
  font-weight: 700;
}

.separator {
  width: rem(30);
  margin-top: rem(5);
}

.button {
  margin-top: rem(10);

  @media bp(sm) {
    margin-top: 0;
    align-self: end;
  }
}

.accordion {
  align-self: flex-start;
}

.textBadge {
  position: absolute;
  right: rem(40);
  top: rem(-12);
}
