.button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: rem(50);
  font-size: rem(18);
  font-weight: bold;
  padding: 0 rem(15);

  @mixin transition opacity;

  &:hover {
    opacity: 0.55;
  }
}
