.container {
  position: relative;
}

.containerAbsolute {
  position: absolute;
  top: 0;
  right: 0;
}

.subContainer {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.button {
  width: auto;
  box-shadow: shadow(tc, cb/70) !important;
  height: rem(40);
  transform: scale(0.7, 0.7);
  transform-origin: 100% 100%;
  contain: content !important;
  padding: 0 rem(15);
}

.elevated {
  top: rem(-10);
}
