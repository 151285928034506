.container {
  contain: content;
  height: rem(120);
  display: grid;
  background-color: clr(g-90);
  grid-template-columns: 1fr rem(90);
  padding: rem(15);
  column-gap: rem(15);
  color: clr(b);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: rem(5);
}

.title {
  font-weight: bold;
  font-size: rem(16);
  line-height: rem(20);
  max-height: rem(60);

  @mixin lineClamp 3;
}

.text {
  font-weight: 500;
  font-size: rem(14);
  line-height: rem(18);

  @mixin lineClamp 3;
}

.subtitle {
  font-weight: 500;
  color: clr(g-40);
  font-size: rem(12);
  line-height: 1;
}

.imageContainer {
  height: rem(90);
}

/**
.variantDefault {}
.variantLarge {}
.variantCard {}
*/

@media bp(md) {
  .responsive {
    column-gap: rem(20);
    height: auto;
    border-radius: rem(4);
    overflow: hidden;
    transition: trn(background-color, box-shadow);

    &.variantDefault {
      padding: 0 rem(20) 0 0;
      grid-template-columns: rem(160) 1fr;
      min-height: rem(90);
    }

    &.variantLarge {
      padding: 0 rem(20) 0 0;
      grid-template-columns: rem(240) 1fr;
      min-height: rem(140);
    }

    &.variantCard {
      grid-template-columns: 1fr;
      padding: 0;

      .imageContainer {
        aspect-ratio: var(--image-width) / var(--image-height);
      }

      .content {
        padding: rem(12) rem(15);
      }

      .subtitle {
        padding-top: rem(5);
      }
    }

    &:hover {
      background-color: clr(w);
      box-shadow: shadow(th, b/10);

      .title {
        color: clr(p-70);
      }

      .subtitle {
        color: clr(b);
      }
    }

    .imageContainer {
      height: 100%;
      order: 1;
      border-radius: 0;
    }

    .content {
      order: 2;
      padding: rem(12) 0 rem(11) 0;
    }

    .title {
      transition: trn(color);
      font-size: rem(20);
      line-height: rem(24);
      max-height: rem(48);

      @mixin lineClamp override-2;
    }

    .text {
      font-size: rem(16);
      line-height: rem(20);

      @mixin lineClamp override-2;
    }

    .subtitle {
      transition: trn(color);
      font-size: rem(14);
    }
  }
}
