.container {
  display: inline-flex;
  column-gap: rem(4);
  letter-spacing: rem(-0.1);
  font-weight: 500;
  align-items: center;
}

.sizeMedium {
  --icon-w: rem(20);
  --icon-h: rem(20);
}

.sizeSmall {
  --icon-w: rem(16);
  --icon-h: rem(16);
}

.icon {
  width: var(--icon-w);
  height: var(--icon-h);
}
