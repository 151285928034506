.linked {
  display: block;

  * {
    pointer-events: none;
  }
}

.container {
  contain: content;

  @mixin transition opacity;
}

.blockHidden {
  pointer-events: none;
  opacity: 0;
}
