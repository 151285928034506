.container {
  padding: rem(5);
  contain: content;

  @media bp(sm) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
}

.imageContainer {
  position: relative;

  @media bp(sm) {
    grid-row: span 7;
  }
}

.image {
  border-radius: rem(4);
  padding-top: 56.25%;
  aspect-ratio: 16 / 9;

  @media bp(sm) {
    padding-top: 0;
    aspect-ratio: auto;
    width: 100%;
    height: 100%;
  }
}

.title,
.tags,
.underTags,
.subtitle,
.text,
.buttonsGrid {
  margin-left: rem(10);
  margin-right: rem(10);

  @media bp(sm) {
    margin-left: rem(25);
    margin-right: rem(25);
  }
}

.title {
  font-size: rem(24);
  line-height: rem(30);
  font-weight: bold;
  margin-top: rem(15);
}

.tags {
  margin-top: rem(7);
}

.subtitle,
.tags,
.text {
  font-size: rem(16);
  line-height: rem(24);
  margin-top: rem(7);
}

.separator {
  margin-top: rem(6);
  width: rem(30);
  align-self: start;
}

.score {
  margin-top: rem(12);
  margin-bottom: rem(12);
  color: clr(p-70);
  transform: scale(1.2, 1.2);
  transform-origin: left center;
}

.msrp {
  color: clr(b/50);
  font-size: rem(14);
  line-height: rem(20);
  font-weight: 700;
}

.subtitle {
  font-weight: bold;
}

.buttonsGrid {
  display: grid;
  row-gap: rem(10);
  margin-top: rem(15);

  @media bp(sm) {
    gap: rem(15);
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: rem(5);
  }

  @media bp(lg) {
    grid-template-columns: auto;
    grid-auto-flow: row;
    row-gap: rem(10);
  }

  @media bp(xl) {
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    row-gap: rem(15);
  }
}

.badgeWrapper {
  position: absolute;
  top: 0;
  right: 0;

  @media bp(md) {
    position: relative;
    top: auto;
    right: auto;
  }
}

.badge {
  padding: 0;
  position: absolute;
  top: rem(5);
  left: rem(-45);
  width: rem(39);
  height: rem(60);
  transform: scale(2);
  transform-origin: top right;
}

.badgeImage {
  background: none;
}

.textBadge {
  z-index: 1;
  position: absolute;
  bottom: rem(5);
  right: rem(5);
}

.button {
  display: block;
  height: rem(40);
  white-space: nowrap;
}
