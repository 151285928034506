.fieldset {
    border: bdr(1, g-60);
    border-radius: rem(7);
    padding: 0 rem(15);
}

.legend {
    padding: 0 rem(9);
    margin-left: rem(-9.6);
}

.legendContent {
    color: clr(g-25);
    font-size: rem(16);
}

.contentWrapper {}

.fieldsetContent {}
