.container {
  padding: rem(16);
  border-radius: rem(16);
  border: bdr(1, b/10);
  display: grid;
  column-gap: rem(16);
  grid-template-columns: auto 1fr;
  grid-template-areas: 'image description';
}

.image {
  grid-area: image;
  width: rem(70);

  @media bp(lg) {
    width: rem(74);
  }
}

.imageImage {
  background-color: transparent;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: description;
  font-size: rem(12);
  font-weight: 500;
  line-height: rem(16);
  letter-spacing: rem(-0.1);

  @media bp(lg) {
    font-size: rem(14);
    line-height: rem(20);
  }
}

.title {
  color: clr(g-20);
}

.button {
  text-decoration: underline;
  color: clr(g-5);
}
