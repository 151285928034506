.container {
  display: grid;
  gap: rem(4);
  contain: content;
  grid-template-rows: auto 1fr;
}

.up,
.down {
  border-radius: rem(10);
  overflow: hidden;
}

.up {
  position: relative;
}

.percent {
  position: absolute;
  top: rem(10);
  right: rem(10);
  height: rem(30);
  padding: 0 rem(15);
  font-size: rem(12);
  line-height: rem(20);
  color: clr(g-25);
  font-weight: bold;
  background-color: clr(w);
  border-radius: rem(20);
  box-shadow: shadow(tg, cb/20);
}

.percentVal {
  margin-right: rem(4);
  font-family: font(narrow);
  font-size: rem(20);
  line-height: rem(30);
  color: clr(p-70);
  font-weight: normal;
}

.down {
  display: flex;
  flex-direction: column;
  row-gap: rem(10);
  padding: rem(15) rem(5) rem(10) rem(5);
}

.title {
  text-align: center;
  font-size: rem(20);
  line-height: rem(28);
  font-weight: bold;
  margin: 0 rem(10);
  border-bottom: bdr(1, g-70);
  padding-bottom: rem(10);
}

.tags {
  flex: 1;
  font-size: rem(16);
  line-height: rem(26);
  text-align: center;
}

.textBadge {
  position: absolute;
  bottom: rem(5);
  right: rem(5);
  z-index: 1;
}
