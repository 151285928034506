.container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: shadow(ti, b/50);
  border: bdr(1, b);
  width: rem(40);
  height: rem(40);
  border-radius: 100%;
  cursor: pointer;
  overflow: hidden;
  contain: strict;
  color: clr(w);
  background-color: clr(b/50);
}

.icon {
  width: rem(18);
  height: rem(18);
}
