.container {
  &:not(&.empty) {
    --outlet-stream-width: calc(100% + 4%);
    --outlet-stream-ml: -2%;
    --outside-mobile-frame: 0;
    --wrapper-max-width: 0;

    iframe {
      margin-top: 0;
    }

    img {
      margin-top: 0;
    }

    @media bp(sm) {
      width: var(--outlet-stream-width);
      margin-left: var(--outlet-stream-ml);
    }
  
    @media bp(md) {
      width: calc(100%);
      margin-left: 0;
    }
  
    @media bp(lg) {
      width: var(--outlet-stream-width);
      margin-left: var(--outlet-stream-ml);
    }
  }
}

.empty {
  .title {
    font-size: rem(18);
    font-weight: bold;
    color: clr(g-60);
  }
}
