.container {
  padding: rem(2) rem(6);
  border-radius: rem(2);
  font-weight: 500;
  font-size: rem(14);
}

.styleWarning {
  color: clr(cw);
  background-color: clr(r-50);
}
