.container {
  display: grid;
  padding: rem(14) rem(16);

  @media bp(lg) {
    padding: rem(4);
    grid-template-columns: auto 1fr;
    column-gap: rem(12);
  }
}

.edit {
  position: absolute;
  top: 0;
  right: rem(5);
}

.imageContainer {
  display: none;

  @media bp(lg) {
    overflow: hidden;
    border-radius: rem(8);
    border: bdr(1, g-90);
    display: block;
    background-color: clr(g-70);
    width: rem(90);
    height: rem(90);
    position: relative;
  }
}

.content {
  display: grid;
  row-gap: rem(8);
  grid-template-columns: auto 1fr;
  align-items: center;

  @media bp(lg) {
    column-gap: rem(16);
    row-gap: rem(3);
    grid-template-rows: 1fr auto 1fr;
  }
}

.title {
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.25;
  grid-column: span 2;
  padding-right: rem(40);

  @mixin lineClamp 1;

  @media bp(lg) {
    padding-right: rem(60);
    font-size: rem(20);
    line-height: 1.4;
    order: 20;
  }
}

.discount {
  display: flex;
  align-items: center;
  column-gap: rem(8);
  color: clr(p-70);
  font-size: rem(11);
  font-weight: 700;
  height: rem(21);
  border-radius: rem(8);
  border: bdr(1, b/4);
  padding: 0 rem(10) 0 rem(8);
  justify-self: flex-start;

  @media bp(lg) {
    grid-column: span 2;
    align-self: flex-end;
    order: 10;
  }
}

.discountIcon {
  flex-shrink: 0;
  width: rem(12);
  height: rem(10);
  stroke: none;
}

.priceButton {
  color: clr(b-22);
  font-size: rem(15);
  font-weight: 700;
  line-height: rem(24);
  display: flex;
  column-gap: rem(8);
  align-items: center;

  @media bp(lg) {
    order: 30;
    align-self: flex-start;
  }
}

.buttonWithDiscount {
  padding-left: rem(8);
  margin-left: rem(8);
  border-left: bdr(1, g-65);

  @media bp(lg) {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }
}

.priceButtonIcon {
  width: rem(17);
  height: rem(14);
  flex-shrink: 0;
}

.couponFirstCol {
  @media bp(lg) {
    display: none;
  }
}

.couponInfo {
  font-size: rem(12);
  font-weight: bold;
  text-align: right;
  padding-right: rem(40);
  padding-left: rem(17);
  justify-self: flex-start;

  @media bp(lg) {
    align-self: flex-start;
    transform: translateY(rem(6));
    padding-right: rem(80);
    padding-left: 0;
    text-align: left;
    order: 40;
  }
}

.couponCustomText {
  font-size: rem(12);
  line-height: rem(14);
  color: clr(g-40);
}
