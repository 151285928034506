.container {
  letter-spacing: rem(1);
  text-transform: uppercase;
  font-size: rem(12);
  font-weight: bold;
  padding-right: rem(15);
}

.icon {
  color: clr(p-70);
  width: rem(8);
  height: rem(8);
  margin-left: rem(10);
  display: inline-block;
  transform: translateY(rem(-1));
}
