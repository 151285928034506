.container {
  position: relative;
  border-radius: rem(10);
  overflow: hidden;
}

.pending {
  pointer-events: none;
}

.slider {
  display: flex;
  overflow-x: auto;
  column-gap: rem(15);
  scroll-snap-type: x mandatory;
  user-select: none;
  scroll-behavior: smooth;

  /* disable scrollbars */
  margin-bottom: rem(-15);

  @mixin scrollHidden;
}

.imageContainer {
  flex-shrink: 0;
  width: 100%;
  scroll-snap-align: center;
}

.image {
  object-fit: contain;
}

.button {
  position: absolute;
  top: calc(50% - rem(20));
  transition: trn(opacity);

  --padding: rem(5);

  @media bp(sm) {
    --padding: rem(15);
  }
}

.prevButton {
  left: var(--padding);
}

.nextButton {
  right: var(--padding);
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
