.author {
  font-style: italic;
  color: clr(g-50);
  display: block;
  margin-top: rem(10);
  font-size: rem(18);
  line-height: rem(26);
  font-weight: normal;

  @media bp(sm) {
    font-size: rem(24);
    line-height: rem(32);
  }
}
