.ad {
  margin: rem(5) 0;

  @media bp(lg) {
    margin: rem(10) 0;
  }
}

.video {
  padding-top: rem(40);

  @media bp(sm) {
    padding-top: 0;
  }
}

.desktop {
  display: none;

  @media bp(md) {
    display: block;
  }
}

.mobile {
  @media bp(md) {
    display: none;
  }
}

.slotcloak {
  overflow: visible;
  position: relative;
  height: rem(264);
  width: rem(300);
}

.slotcloak[style*='display: block'] > .pw-adunit:before {
  content: 'Loading Ad...';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-content: center;
  align-content: center;
  justify-content: center;
  font-size: rem(14);
  color: #999;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  box-sizing: border-box;
}

.slotcloak[style*='display: block'] > .pw-adunit {
  position: relative;
}

.slotcloak[style*='display: block'] > .pw-adunit > div {
  position: relative;
}

.slotcloak[style*='display: block'] > .pw-adunit:before {
  display: none !important;
}

.pw-gtr-box {
  height: rem(550);
  width: 70%;
  justify-content: center;
  position: relative;
  margin-bottom: rem(10);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  overflow: visible !important;
  padding-top: 0;
  left: calc(50% - (70% / 2));
}

.pw-gtr-box > div {
  z-index: 1;
}

.pw-gtr-box:before {
  content: 'ADVERTISEMENT';
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  height: rem(20);
  width: 100%;
  text-align: center;
  color: #a5a5a5;
  font-size: rem(11);
  letter-spacing: rem(2);
  top: rem(2);
}

.pw-gtr-box:after {
  content: '\00B7\00B7\00B7';
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #c4c4c4;
  font-size: rem(62);
  letter-spacing: rem(2);
  z-index: 0;
  animation: fadeOut 1s linear 0s infinite;
  animation-direction: alternate;
}

.pw-gtr-box .slotcloak {
  position: static;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
}

.pw-hashes-border {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: rem(550);
  margin: 0;
  clear: both;
  background-color: #fafafa;
}

.pw-half-pipe {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

@media (max-width: rem(350)) {
  .pw-half-pipe {
    padding-bottom: rem(15);
  }
}

.pw-hp-sticky {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  min-height: rem(50);
  top: 0;
}

.pw-hp-sticky::before {
  content: '\00B7\00B7\00B7';
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c4c4c4;
  font-size: rem(62);
  letter-spacing: rem(2);
  z-index: 0;
  animation: fadeOut 1s linear 0s infinite;
  animation-direction: alternate;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pw-hp-sticky > .slotcloak {
  height: initial !important;
  overflow: hidden !important;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.pw-hp-sticky .pw-adunit {
  z-index: 1;
}

.pw-half-pipe.pw-gtr-sticky {
  display: block;
}

.pw-hp-sticky.pw-gtr-sticky {
  position: sticky;
  position: -webkit-sticky;
}
