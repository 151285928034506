.refLink {
  line-height: rem(30);
  border-radius: rem(15);
  color: clr(w);
  background-color: clr(b);
  justify-self: end;
  margin-top: rem(5);
  padding: 0 rem(30) 0 rem(15);

  @media bp(sm) {
    display: none;
  }
}

.refLinkDesktop {
  display: none;

  @media bp(sm) {
    line-height: 1;
    display: block;
    justify-self: start;
    color: clr(g-25);
  }
}

.titleFont {
  font-size: rem(24);
  line-height: rem(30);
  font-weight: bold;
}
