.container {
  height: rem(1);
  border-radius: rem(1);
}

.slight {
  background-color: clr(b/10);
}

.bright {
  background-color: clr(p-70);
}
