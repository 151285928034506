.container {
  margin: rem(32) auto;
}

.placeholder {
  background-color: clr(b);
  margin-top: rem(32);
  color: clr(w);
  height: rem(470);
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowIcon {
  width: rem(10);
  height: rem(10);
  fill: clr(b);

  @mixin transition transform;
}

.selectContainer {
  background-color: clr(g-85);
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  padding: rem(10);

  @media bp(md) {
    align-items: center;
    flex-direction: row;
  }
}

.selectLabel {
  font-size: rem(12);
  color: clr(b/40);

  @media bp(md) {
    font-size: rem(15);
    margin-bottom: initial;
  }
}

.currentSelection {
  color: clr(b);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownContent{
  left: 0;
  top: calc(100% + rem(11));
  display: none;
  z-index: 20;
  list-style: none;
  position: absolute;
  background-color: clr(g-85);
  width: 100%;
  max-height: rem(320);
  overflow-y: scroll;
  box-shadow: 0 rem(8) rem(16) 0 clr(w/20);
  font-size: rem(14);
  color: clr(b);

  @media bp(md) {
    right: 0;
    left: auto;
    width: rem(320);
  }
}

.dropdownOptionsContainer{
  padding: rem(12) rem(16);
  border: bdr(1, cg-50);
}

.currentSelectionContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(16);
}

.dropdownOption{
  cursor: pointer;
  list-style: none;
}

.filterContainer{
  display: flex;
  border: bdr(1, cg-50);
  background-color: clr(w);
  align-items: center;
  position: sticky;
  top: 0;
  padding-left: rem(8);
}

.resetFilter{
  padding-right: rem(8);
  font-size: rem(16);
}

.emptyDropdownOption{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: rem(8) rem(16);
  cursor: default;
}

.selectedOption {
  background-color: clr(b);
  color: clr(w);
}

.selectedCheckMark {
  margin-right: rem(10);
}

.dropdown{
  position: relative;
  cursor: pointer;
  width: 100%;

  @media bp(md) {
    width: auto;
  }
}

.dropdownFilter {
  height: rem(32);
  display: block;
  color: clr(b);
  border: none;
  background-color: transparent;
  width: 100%;
  padding: rem(8) 0;

  &::placeholder{
    color: clr(b/50);
  }
}

.dropdownOptionActionButton{
  width: 100%;
  padding: rem(8) rem(16);
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: clr(b);
    color: clr(w);
  }
}

.dropdownOpen{
  .dropdownContent{
    display: block;
  }

  .arrowIcon{
    transform: rotate(180deg);
  }
}
