.container {
  display: flex;
  flex-direction: column;
  row-gap: rem(16);
}

.title {
  display: flex;
  align-items: center;
  column-gap: rem(8);
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(30);
  letter-spacing: rem(-0.5);
}

.titleIcon {
  color: clr(b-10);
  flex-shrink: 0;
  width: rem(24);
  height: rem(24);
}

.summary {
  font-size: rem(18);
  line-height: rem(28);
  letter-spacing: rem(-0.1);
}

.sourcesTitle {
  font-size: rem(14);
  font-weight: 600;
  line-height: rem(20);
  letter-spacing: rem(-0.3);
}

.sources {
  display: flex;
  column-gap: rem(4);
  flex-wrap: wrap;
}

.source {
  color: clr(g-25);
  background-color: clr(g-90);
  border-radius: rem(1000);
  padding: rem(2) rem(8);
  font-size: rem(14);
  font-weight: 500;
  line-height: rem(20);
  letter-spacing: rem(-0.1);
}
