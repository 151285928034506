.container {
  --arrow-width: rem(20);
  --scroll-width: rem(4);
  --gap: rem(4);
  --scroll-gap: rem(3);
  --btn-depth: rem(4);

  /* manually minus 0.5 for a more correct calculation */
  --helper-width: calc((var(--arrow-width) / 2) - (var(--scroll-width) / 2) - rem(0.5));
  --btn-height: var(--button-height, rem(36));
  --discount-height: rem(22);

  &.bordered {
    padding: rem(4);
    border: bdr(1, b/5);
    border-radius: rem(8);
    background-color: clr(cw);
  }

  .button {
    display: block;
    white-space: nowrap;
    margin-bottom: var(--btn-depth);
    height: var(--btn-height);

    &.withDiscount {
      margin-bottom: 0;
    }
  }
}

.panelBox {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .discountWrapper,
  .button,
  .stdBox {
    width: 100%;
  }

  .arrow {
    --base-arrow-height: calc(var(--btn-height) + var(--btn-depth));

    width: var(--arrow-width);
    height: var(--base-arrow-height);
    background-color: clr(cb/4);
    border-radius: rem(4);
    border: bdr(1, cb/10);
    margin-left: var(--gap);
    display: flex;
    justify-content: center;
    align-items: center;

    @mixin transition height;

    &.arrowDiscount {
      height: calc(var(--base-arrow-height) + var(--discount-height));
    }

    .arrowIcon {
      width: rem(10);
      height: rem(10);
      fill: clr(cb);

      @mixin transition transform;
    }

    &.arrowDiscount.arrowOpened.arrowScrollable {
      /* Safari */
      @media not all and (min-resolution: 0.001dpcm) {
        height: calc(var(--base-arrow-height) + var(--discount-height));
      }

      /* Firefox */
      @supports (-moz-appearance:none) {
        height: calc(var(--base-arrow-height) + var(--discount-height));
      }
    }

    &.arrowOpened.arrowScrollable {
      height: var(--base-arrow-height);
    }

    .iconOpened {
      transform: rotate(180deg);
    }
  }
}

.collapsed {
  /* manually plus 1 for a more correct calculation */
  --base-width: calc(100% - var(--arrow-width) - var(--gap) + rem(1));
  --scroll-height: calc(2 * var(--btn-height) + 2 * var(--btn-depth) + var(--scroll-gap));

  display: flex;
  width: 100%;
  position: relative;
  max-height: rem(140);
  overflow-x: hidden;
  overflow-y: auto;
  transition: margin-top 0.2s ease-in-out;

  &.viewFull {
    max-height: calc(var(--scroll-height) + 2 * var(--discount-height));
  }

  &.viewPart {
    max-height: calc(var(--scroll-height) + var(--discount-height));
  }

  &.viewNone {
    max-height: var(--scroll-height);
  }

  &.isCollapsedOpen {
    margin-top: var(--gap);
  }

  &:not(&.isCollapsedOpen) {
    &.isScrollable {
      &::-webkit-scrollbar-track {
        margin: rem(8) 0 rem(8);
      }
    }
  }

  &.isScrollable {
    overflow-y: scroll;

    /* Firefox */
    @supports (-moz-appearance:none) {
      scrollbar-width: thin;
      scrollbar-color: clr(p-70) clr(cb/4);
    }

    &::-webkit-scrollbar {
      width: var(--scroll-width);
    }

    &::-webkit-scrollbar-track {
      background: clr(cb/4);
      border-radius: rem(4);
      margin: rem(8) 0 rem(8);
    }

    &::-webkit-scrollbar-thumb {
      background: clr(p-70);
      border-radius: rem(4);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: clr(p-70/80);
    }

    &.forceScroll::-webkit-scrollbar-track {
      margin: rem(-10) 0 rem(8);
    }

    /* Safari */
    @media not all and (min-resolution: 0.001dpcm) {
      &.forceScroll::-webkit-scrollbar-track {
        margin: rem(8) 0 rem(8);
      }
    }

    .accordion {
      width: calc(var(--base-width) + var(--helper-width) + var(--scroll-width));

      /* Firefox */
      @supports (-moz-appearance:none) {
        /* manually plus 1 for a more correct calculation */
        width: calc(var(--base-width) + var(--scroll-width) + rem(1));
      }
    }
  }

  .accordion {
    width: var(--base-width);

    .collapsedButtons {
      display: flex;
      flex-direction: column;
      gap: var(--scroll-gap);
    }
  }
}

.scrollWrapper {
  display: flex;

  .helperGap {
    width: var(--helper-width);
    height: 100%;

    /* Firefox */
    @supports (-moz-appearance:none) {
      width: rem(4);
    }
  }
}
