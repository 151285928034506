.container {
  &,
  tr,
  td,
  tbody {
    display: block;
  }
}

.collapsed {
  .body {
    display: grid;
    row-gap: rem(4);

    @media bp(lg) {
      column-gap: rem(4);
      grid-template-columns: repeq(2);
    }
  }

  .row {
    background-color: clr(g-90);
    border-radius: rem(2);
    display: grid;
    grid-template-columns: 40fr repeat(calc(var(--columns) - 1), 55fr);
  }

  .cell {
    padding: rem(7) rem(10);
    font-size: rem(15);
    line-height: rem(22);
    position: relative;

    &:not(:first-child) {
      padding: rem(7) rem(10) rem(7) rem(12);

      &::before {
        content: '';
        position: absolute;
        display: block;
        left: rem(-1);
        width: rem(2);
        border-radius: rem(1);
        background-color: clr(g-70);
        top: rem(7);
        bottom: rem(7);
      }
    }
  }
}

.expanded {
  .body {
    display: grid;
  }

  .row {
    display: grid;

    @media bp(lg) {
      grid-template-columns: 20fr repeat(calc(var(--columns) - 1), 55fr);

      &:nth-child(2n) .cell {
        background-color: clr(g-85);
        border-radius: rem(2);
      }
    }
  }

  .cell {
    font-size: rem(16);
    padding: rem(10) rem(13);

    &:first-child {
      color: clr(w);
      background-color: clr(b);
      padding: rem(10) rem(15);
      font-size: rem(18);
    }

    &:not(:first-child) {
      border-bottom: bdr(2, b);
      border-left: bdr(2, b);
      border-right: bdr(2, b);
    }

    @media bp(lg) {
      &:first-child {
        padding: rem(10) rem(15);
        background-color: clr(w);
        color: clr(b);
        font-size: rem(14);
      }

      &:not(:first-child) {
        font-size: rem(14);
        padding: rem(10) rem(15) rem(10) rem(17);
        border: none;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          display: block;
          left: rem(-1);
          width: rem(2);
          background-color: clr(g-70);
          top: 0;
          bottom: 0;
        }
      }
    }
  }
}
