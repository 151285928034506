.container {
  --button-height: rem(50);

  display: flex;
  justify-content: center;
}

.textBadge {
  z-index: 1;
  position: absolute;
  right: rem(5);
  top: rem(-12);
  pointer-events: none;
}

.buttonBlock {
  position: relative;
}
