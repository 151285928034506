.container {
  contain: content;
  display: grid;
  grid-template-columns: 1fr rem(120);
  padding: rem(10) rem(10) rem(20) rem(20);
  background-color: clr(g-90);
  border-radius: rem(2);
}

.lang {
  font-weight: bold;
  line-height: rem(22);
  color: clr(g-50);
  text-transform: uppercase;
  align-self: center;
}

.pre {
  padding: rem(10) 0;
  grid-column: span 2;
  overflow: auto;
}

.code {
  color: clr(g-20);
  /* stylelint-disable */
  font-family: Courier, serif;
  /* stylelint-enable */
  font-size: rem(15);
  line-height: rem(25);
}

.button {
  height: rem(40);
}
