.container {
  display: grid;

  @media bp(sm) {
    .body {
      display: block;
      padding-top: rem(15);
    }

    .imageButton {
      width: rem(130);
      float: left;
      margin: 0 rem(15) rem(10) 0;
      transform: translateY(rem(5));
    }
  }
}

.feedbackWidget {
  transform: scale(0.8);
  transform-origin: top right;
  grid-template-columns: auto auto auto auto !important;
  align-items: center;
  justify-content: flex-end;

  @media bp(sm) {
    grid-template-columns: auto auto auto !important;
  }
}

.openButtonLeftIcon {
  width: rem(18);
  height: rem(18);
  flex-shrink: 0;
}

.opened {
  .openButtonIcon {
    transform: rotate(180deg);
  }

  .body {
    height: var(--scroll-height);
  }
}

.openButton {
  font-weight: bold;
  font-size: rem(20);
  line-height: rem(28);
  text-align: left;
  padding: rem(10) rem(20);
  background-color: clr(g-90);
  border: bdr(1, g-70);
  border-radius: rem(10);
  display: flex;
  align-items: center;
  column-gap: rem(20);
  overflow-wrap: anywhere;
}

.buttonTitle {
  flex: 1;
}

.openButtonIcon {
  height: rem(7);
  width: rem(12);
  transition: trn(transform);
  flex-shrink: 0;
}

.body {
  height: 0;
  padding-top: rem(10);
  transition: trn(height);
  overflow: hidden;
  contain: strict;
  display: grid;
  row-gap: rem(10);
}

.imageButton {
  width: 100%;
}

.popupContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupBlock {
  width: 80vw;
}
