.container {
  background-color: clr(g-90);
  border-radius: rem(2);
  padding: rem(5) rem(10);
  display: flex;
  column-gap: rem(10);
  align-items: center;

  @media bp(sm) {
    padding: rem(10);
  }
}

.link {
  flex: 1;
  display: grid;
  grid-template-columns: rem(40) 1fr;
  column-gap: rem(10);
  font-weight: bold;

  @media bp(sm) {
    grid-template-columns: rem(40) 1fr auto;
  }
}

.imageContainer {
  border-radius: 100%;
  height: rem(40);
  grid-row: span 2;
  align-self: center;

  @media bp(sm) {
    grid-row: span 1;
  }
}

.author {
  align-self: end;
  font-size: rem(16);
  line-height: 1.1;

  @media bp(sm) {
    align-self: center;
  }
}

.text {
  align-self: start;
  color: clr(g-40);
  font-size: rem(12);

  @media bp(sm) {
    align-self: center;
    font-size: rem(14);
  }
}

.buttonIcon {
  width: rem(14);
  height: rem(14);
  color: clr(g-40);
  margin: rem(10);
}
