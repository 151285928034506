.container {
  border-radius: rem(2);
  border: rem(1) dashed currentColor;
  padding: rem(10);
  font-weight: 500;
  color: clr(r-10);
  line-height: 1.2;
  font-size: rem(16);
  display: flex;
  align-items: center;
  column-gap: rem(16);

  @media bp(lg) {
    column-gap: rem(20);
    font-size: rem(20);
    padding: rem(15);
  }
}

.icon {
  flex-shrink: 0;
  width: rem(20);
  height: rem(20);

  @media bp(lg) {
    width: rem(24);
    height: rem(24);
  }
}
