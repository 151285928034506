.container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  [data-testid='left-image'] + div + div,
  [data-testid='left-image'] + div + div + div {
    > div {
      height: rem(28);
      border-radius: rem(2);
      box-shadow: shadow(tc, b/20);
      background-color: clr(w) !important;
      line-height: rem(28) !important;
      padding: 0 rem(20) !important;
      bottom: rem(10) !important;
      left: rem(10) !important;
      font-size: rem(14);
      letter-spacing: rem(-0.16);
      color: clr(b) !important;
      font-weight: bold;
      top: auto !important;
      transform: none !important;
      transition: trn(opacity);
    }
  }

  [data-testid='left-image'] + div + div + div {
    > div {
      left: auto !important;
      right: rem(10) !important;
    }
  }
}

.handle {
  width: rem(62) !important;
  height: rem(26) !important;
  border-radius: rem(13) !important;
  box-shadow: shadow(tc, b/20) !important;
  position: relative;
  padding: rem(7) rem(8);
  background-color: clr(w);

  &::before,
  &::after {
    display: block;
    position: absolute;
    width: rem(7);
    height: rem(12);
    margin-top: rem(-6);
    top: 50%;
    content: '';
    background-size: contain;
    left: rem(8);
  }

  &::after {
    background-size: contain;
    left: auto;
    right: rem(8);
  }
}

.handleDiv {
  width: rem(1) !important;
  height: rem(12) !important;
  border-radius: rem(0.5);
  position: absolute;
  top: rem(7);
  background-color: clr(g-65);
  border-width: 0 !important;
  border-style: none !important;
  border-color: transparent !important;
  border-image: none !important;
  margin-left: rem(-2) !important;
  margin-right: 0 !important;
  left: 50%;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: rem(1);
    height: rem(12);
    border-radius: rem(0.5);
    top: 0;
    background-color: clr(g-65);
    left: rem(-3);
  }

  &::after {
    left: rem(-6);
  }

  &:nth-child(2) {
    margin-left: rem(1) !important;

    &::before {
      left: rem(3);
    }

    &::after {
      left: rem(6);
    }
  }
}
