.container {
  contain: strict;
  height: rem(90);
  display: grid;
  grid-template-columns: 1fr rem(90);
  column-gap: rem(15);
  row-gap: rem(5);

  @media bp(md) {
    height: rem(153);
    gap: rem(30);
    grid-template-columns: 1fr rem(270);
  }
}

.title {
  order: 1;
  font-size: rem(16);
  line-height: rem(20);
  font-weight: bold;
  max-height: rem(60);

  @mixin lineClamp 3;

  @media bp(md) {
    font-size: rem(24);
    line-height: rem(30);
    max-height: rem(90);
  }
}

.subtitle {
  order: 3;
  align-self: end;
  font-size: rem(12);
  color: clr(cg-50);
}

.imageContainer {
  order: 2;
  height: rem(90);
  grid-row: span 2;

  @media bp(md) {
    width: rem(270);
    height: rem(153);
  }
}
