.container {
  display: grid;
  position: relative;
}

.widget {
  display: grid;
  row-gap: rem(10);
  justify-items: stretch;
  padding: rem(10) rem(5);

  @media bp(sm) {
    padding: rem(15);
    grid-template-columns: 1fr rem(270);
  }
}

.title {
  margin-top: rem(5);
  justify-self: center;

  @media bp(sm) {
    justify-self: start;
  }
}

.msrp {
  color: clr(b/50);
  font-size: rem(14);
  line-height: rem(20);
  font-weight: 700;
}

.separator {
  display: none;

  @media bp(sm) {
    width: rem(30);
    display: block;
  }
}

.textBadge {
  position: absolute;
  right: rem(40);
  top: rem(-12);
}
