.container {
  pointer-events: none;
  position: absolute;

  .anchor {
    position: absolute;
    bottom: rem(84);

    @media bp(xl) {
      bottom: rem(104);
    }
  }
}
