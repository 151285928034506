.container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: rem(24);
  grid-template-areas:
    'image'
    'description'
    'sidebar';

  @media bp(lg) {
    row-gap: rem(16);
    grid-template:
      'image sidebar' auto
      'description sidebar' 1fr / 563fr 322fr;
    column-gap: rem(24);
  }
}

.imageContainer{
  background: clr(cw);
}

.imageBox {
  aspect-ratio: 16 / 9;
  grid-area: image;
  position: relative;
  border-radius: rem(12);
  overflow: hidden;
}

.imageImage {
  object-fit: contain;
  background-color: transparent;
}

.badge {
  position: absolute;
  top: rem(5);
  right: rem(5);
  width: rem(60);
  height: rem(110);
}

.badgeImage {
  background-color: transparent;
  object-fit: contain;
  object-position: top right;
}

.description {
  grid-area: description;
}

.title {
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(30);
  letter-spacing: rem(-0.5);
}

.tags {
  margin-top: rem(4);
  font-size: rem(16);
  font-weight: 500;
  line-height: rem(24);
  letter-spacing: rem(-0.1);
  color: clr(g-25);
}

.score {
  box-shadow: rem(0) rem(1) rem(3) rem(-1) clr(b/20);
  border-radius: rem(4);
  padding: rem(6, 8);
  background-color: clr(w);
  color: clr(p-70);
  margin-top: rem(12);
}

.msrp {
  margin-top: rem(12);
  color: clr(g-25);
  font-size: rem(16);
  font-weight: 500;
  line-height: rem(24);
  letter-spacing: rem(-0.1);
}

.subtitle {
  margin-top: rem(24);
  font-size: rem(20);
  font-weight: 600;
  line-height: rem(26);
  letter-spacing: rem(-0.5);

  @media bp(lg) {
    font-weight: 500;
  }
}

.text {
  margin-top: rem(12);
  font-size: rem(16);
  font-weight: 500;
  line-height: rem(24);
  letter-spacing: rem(-0.1);
}

.sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  row-gap: rem(24);
  background-color: clr(g-90);
  border-radius: rem(12);
  padding: rem(16);
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: rem(8);

  @media bp(lg) {
    margin-bottom: rem(24);
  }
}

.featuresNegative {
  --selection: clr(r-10);
}

.featuresPositive {
  --selection: clr(p-40);
}

.featuresTitle {
  font-size: rem(20);
  font-weight: 600;
  line-height: rem(26);
  letter-spacing: rem(-0.5);
  color: var(--selection);
}

.featuresList {
  margin-top: rem(9);
  display: grid;
  row-gap: rem(9);
  list-style: none;
}

.featureIcon {
  color: var(--selection);
  width: rem(12);
  height: rem(12);
}

.feature {
  column-gap: rem(10);
  align-items: baseline;
  display: flex;
  font-size: rem(16);
  font-weight: 400;
  line-height: rem(24);
  letter-spacing: rem(-0.1);
}
