.container {
  background: linear-gradient(transparent 50%, clr(g-90) 50%);
  border-radius: rem(4);
}

.line {
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: rem(9) rem(4) rem(5) rem(4);
  align-items: center;
  font-size: rem(12);
  line-height: 1;
  background: clr(w);
  border-radius: 0 0 rem(4) rem(4);
}

.save .saveContent {
  color: clr(g-40);
}

.discount {
  color: clr(r-20);
}

.coupon {
  margin-left: rem(6);
  padding-left: rem(6);
  border-left: bdr(1, cg-20);
}

.unBordered {
  margin-left: rem(6);
  padding-left: rem(6);
}
