.content {
  transition: trn(height);
  overflow: hidden;
  contain: strict;
  height: 0;
}

.opened {
  height: var(--scroll-height);
}

.buttonIcon {
  width: rem(21);
  height: rem(21);
  transform: rotate(180deg);
}

.buttonIconOpen {
  transform: none;
}

.toggle {
  column-gap: rem(8);
  width: 100%;
}

.collapsible {
  padding-bottom: rem(15);

  @media bp(sm) {
    padding-bottom: rem(20);
  }
}
