.subContainer {
  margin: 0 auto;
  position: relative;
}

.credits {
  position: absolute;
  left: rem(10);
  top: rem(10);
  right: rem(10);
}

.badgeContainer {
  width: rem(60);
  height: rem(100);

  @media bp(sm) {
    width: rem(80);
    height: rem(125);
  }
}

.badge {
  object-fit: contain;
  background-color: transparent;
  object-position: 50% top;
}
