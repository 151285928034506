.content {
  overflow-wrap: break-word;
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.56;

  hr {
    color: clr(g-65);
  }

  q,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  hr,
  table {
    &:not(:first-child) {
      margin-top: rem(15);
    }

    &:not(:last-child) {
      margin-bottom: rem(15);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    font-weight: bold;
    contain: content;
  }

  h1 {
    font-size: rem(30);
    line-height: 1.27;
  }

  h2 {
    font-size: rem(24);
    line-height: 1.27;
  }

  h3 {
    font-size: rem(20);
    line-height: 1.27;
  }

  li {
    position: relative;

    &:not(:first-child) {
      margin-top: rem(10);
    }
  }

  ul {
    display: block;
    margin: rem(20) 0;
    list-style-type: none;
    padding: 0;

    li {
      padding: 0 0 0 rem(20);
    }

    li::before {
      top: rem(11);
      position: absolute;
      content: '';
      display: block;
      background-color: clr(p-70);
      width: rem(6);
      height: rem(6);
      left: 0;
    }
  }

  img:not(div.pw-ad-scroll-container .pw-in-article img),
  iframe:not(div.pw-ad-scroll-container .pw-in-article iframe) {
    margin-top: rem(30);
    width: 100%;
  }

  img {
    height: auto;
  }

  > img {
    margin: rem(30) 0;
  }

  > a > img {
    margin: rem(30) 0;
  }

  a {
    color: clr(b);
    display: inline;
    text-underline-offset: rem(3);
  }

  a:not([data-tooltip-id]) {
    text-decoration: underline;
    text-decoration-color: clr(b);
    text-decoration-thickness: rem(0.8);

    &:hover {
      background: linear-gradient(
        to top,
        clr(p-70/30) 0%,
        clr(p-70/30) 50%,
        transparent 50%,
        transparent 100%
      );
      text-decoration: none;
    }
  }

  [data-tooltip-id] {
    text-decoration: underline;
    text-decoration-style: dotted;

    &:hover {
      text-decoration: underline;
    }
  }

  strong,
  b {
    font-weight: bold;
  }

  pre {
    overflow-x: auto;
  }

  p {
    max-width: 100%;
    overflow-x: hidden;
  }

  q {
    contain: content;
    font-size: rem(26);
    font-weight: bold;
    font-style: italic;
    line-height: 1.31;
    text-align: center;
    padding: 0 rem(20);
    display: block;

    &::before,
    &::after {
      content: none;
    }
  }

  table,
  tbody,
  tr,
  td {
    display: block;
  }

  blockquote {
    margin: rem(30) 0 rem(20) 0;
    padding: rem(35) rem(20) rem(20) rem(20);
    border: bdr(2, g-90);
    border-radius: rem(4);
    line-height: rem(18);
    font-style: italic;
    font-weight: bold;
    position: relative;

    &::before {
      background: clr(p-70) no-repeat center center path(quote-icon);
      position: absolute;
      content: '';
      width: rem(40);
      height: rem(40);
      border-radius: 100%;
      top: rem(-20);
      left: calc(50% - rem(20));
    }

    p {
      margin: 0;
      overflow: hidden;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;

    img {
      width: auto;
      height: auto;
      margin: 0;
    }

    thead {
      display: none;
    }

    tr {
      border: bdr(1, b);
    }

    td {
      padding: rem(5) rem(15);

      &:first-child {
        font-weight: bold;
        line-height: 1.11;
        color: clr(w);
        padding: rem(10) rem(15);
        background-color: clr(b);
      }
    }
  }

  @media bp(sm) {
    table {
      display: table;

      tbody {
        display: table-row-group;
      }

      thead,
      th {
        font-size: rem(16);
        line-height: rem(20);
      }

      tbody,
      td {
        font-size: rem(14);
        line-height: rem(24);
      }

      thead {
        display: table-header-group;
      }

      tr {
        display: table-row;
        border: none;
      }

      td:first-child {
        background-color: transparent;
        color: clr(b);
        font-weight: 400;
        padding: rem(11) rem(20);
      }

      td,
      th {
        display: table-cell;
        text-align: left;
        border-right: bdr(1, b/5);
        margin: 0;
        padding: rem(11) rem(20);

        &:last-child {
          border-right: none;
        }
      }

      th {
        border-color: clr(g-30);
        background-color: clr(b);
        color: clr(w);
        font-weight: bold;
        line-height: 1.11;
        padding: rem(15) rem(20);

        a {
          color: clr(p-70);
        }
      }

      tbody tr:nth-child(even) td {
        background-color: clr(g-90);
      }
    }

    blockquote {
      margin: rem(30) rem(30) rem(30) rem(50);
      padding: rem(30) rem(30) rem(30) rem(45);

      &::before {
        top: calc(50% - rem(20));
        left: rem(-20);
      }
    }

    q {
      font-size: rem(36);
      line-height: 1.22;
      padding: 0 rem(100);
    }

    q,
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    hr,
    table {
      &:not(:first-child) {
        margin-top: rem(20);
      }

      &:not(:last-child) {
        margin-bottom: rem(20);
      }
    }

    h1 {
      font-size: rem(44);
      line-height: 1.18;
    }

    h2 {
      font-size: rem(32);
      line-height: 1.25;
    }

    h3 {
      font-size: rem(24);
      line-height: 1.33;
    }

    h4 {
      font-size: rem(20);
      line-height: 1.4;
    }

    h5 {
      font-size: rem(16);
      line-height: 1.25;
    }
  }
}
