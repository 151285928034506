.container {
  display: grid;
  gap: rem(30) rem(10);
  align-items: start;
  grid-template-columns: repeq(var(--columns));
}

.caption {
  margin-top: rem(10);
  display: block;
  font-weight: bold;
  font-size: rem(14);
  line-height: rem(16);
  color: clr(g-30);
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
}
