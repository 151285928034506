.container {
  display: grid;
  row-gap: rem(10);

  @media bp(lg) {
    grid-template-columns: 1fr auto;
    column-gap: rem(10);
  }
}

.title {
  font-size: rem(16);
  line-height: 1.25;
  font-weight: 700;
  color: clr(b);
}

.button {
  display: none;

  @media bp(lg) {
    column-gap: rem(9);
    align-items: center;
    display: flex;
    color: clr(b);
    opacity: 0.3;
    font-size: rem(16);
    line-height: 1.5;
    text-decoration: underline;
  }
}

.buttonIcon {
  width: rem(17);
  height: rem(11);
}

.cards {
  grid-column: 1 / -1;
}

.accordionPending {
  height: rem(127);

  @media bp(lg) {
    height: rem(148);
  }
}
