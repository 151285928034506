.header {
  margin-bottom: rem(7);
}

.gallery {
  border-radius: rem(10);
  margin-bottom: rem(5);
  position: relative;
}

.label {
  display: none;

  @media bp(lg) {
    padding: 0 rem(8);
    column-gap: rem(7);
    font-weight: 500;
    position: absolute;
    display: flex;
    left: rem(8);
    top: rem(8);
  }
}
