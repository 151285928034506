.container {
  container: ncpoll / inline-size;
}

.nextButton {
  margin-top: rem(20);
  min-width: rem(200);
  height: rem(50);

  @mixin transition opacity;
}

.disabled {
  opacity: 0;
  pointer-events: none;
}

.poll {
  @mixin transition opacity;
}

.submitting {
  pointer-events: none;
  opacity: 0.7;
}

.choicesDoubleCols {
  @media bp(sm) {
    column-gap: rem(15);
    grid-template-columns: repeq(2);
  }
}

.loginRequired {
  margin-top: rem(20);
  align-items: center;
  border-radius: rem(8);
  box-shadow: shadow(tg, b/10);
  padding: rem(16);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'icon title title'
    'icon text text';
  column-gap: rem(8);
  row-gap: rem(2);

  @container ncpoll (min-width: rem(590)) {
    display: flex;
    row-gap: 0;
  }
}

.loginRequiredButton {
  color: clr(p-40);
  font-weight: 600;
}

.loginRequiredIconHolder {
  grid-area: icon;
  margin-right: rem(8);
  flex-shrink: 0;
}

.loginRequiredIcon {
  border-radius: 100%;
  padding: rem(7);
  background-color: clr(b);
  color: clr(w);
  box-sizing: content-box;
  width: rem(18);
  height: rem(18);
}

.loginRequiredTitle {
  grid-area: title;
  font-size: rem(20);
  font-weight: 600;
  line-height: rem(24);
  letter-spacing: rem(-0.5);
}

.loginRequiredText {
  grid-area: text;
  font-size: rem(16);
  font-weight: 500;
  line-height: rem(24);
  letter-spacing: rem(-0.1);
}
