.container {
  --primary-btn-color: var(--primary-color, clrraw(p-70));
  --secondary-btn-color: var(--secondary-color, clrraw(p-30));

  contain: strict;
  overflow: hidden;
  font-weight: bold;
  font-size: rem(18);
  border-radius: rem(2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fill {
  box-shadow: shadow(tc, --primary-btn-color/50);
  background-color: clr(--primary-btn-color);
  color: clr(cw);

  @mixin transition background-color;

  &:hover {
    background-color: clr(--secondary-btn-color);
  }
}

.outline {
  box-shadow: shadow(tk, --primary-btn-color/30);
  background-color: clr(w);
  border: bdr(2, p-70);
  color: clr(p-70);
}
