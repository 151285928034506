.container {
  --gap: rem(16);
  --button-offset: rem(88);

  position: relative;

  @media bp(lg) {
    margin-left: var(--button-offset);
    margin-right: var(--button-offset);
  }

  @media bp(xxl) {
    margin-left: 0;
    margin-right: 0;
  }

  .buttonPrev,
  .buttonNext {
    position: absolute;
    top: rem(0);
    bottom: rem(0);
    width: rem(75);
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: rem(10);
    background-color: clr(g-90);
    transition: trn(opacity);

    &.hidden {
      display: none !important;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    @media bp(lg) {
      display: flex;
    }
  }

  .buttonPrev {
    left: calc(-1 * var(--button-offset));
  }

  .buttonNext {
    right: calc(-1 * var(--button-offset));
  }

  .iconPrev,
  .iconNext {
    width: rem(17);
    height: rem(30);
  }

  .iconPrev {
    transform: rotate(180deg);
  }

  .scroller {
    @media bp(lg) {
      overflow: hidden;
    }
  }

  .scrollView {
    --col-width: calc(
      calc(100% - calc(var(--gap) * calc(var(--num-of-columns) - 1))) / var(--num-of-columns)
    );

    display: grid;
    gap: var(--gap);
    overflow-x: scroll;
    overflow-y: clip;
    user-select: none;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    transition: trn(transform);

    @media bp(lg) {
      overflow: visible;
      grid-auto-columns: var(--col-width);
      transform: translateX(calc(-1 * (var(--active-index) * (var(--gap) + var(--col-width)))));
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .card {
    scroll-snap-align: center;
  }
}
