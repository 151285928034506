.container {
  display: flex;
  flex-direction: column;
  row-gap: rem(16);

  --placeholder-cards-height: calc(var(--placeholder-count) * rem(90));
  --placeholder-separator-height: calc((var(--placeholder-count) - 1) * rem(33));

  min-height: calc(
    rem(22) + (var(--placeholder-separator-height) + var(--placeholder-cards-height))
  );
}

.title {
  font-size: rem(18);
  line-height: rem(22);
  font-weight: 600;
  letter-spacing: rem(-0.3);
}

.rowSeparator {
  color: clr(b/10);
}
