.container {
  @media bp(sm) {
    overflow-x: auto;
  }
}

.tableLabel {
  display: inline-block;

  &::after {
    content: ',';
    margin-right: rem(4);
  }

  &:last-of-type::after {
    content: ':';
  }

  @media bp(sm) {
    display: none;
  }
}

.hidden {
  @media bp(sm) {
    display: none !important;
  }
}
