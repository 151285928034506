.all {
  --wrapper-max-width: calc(100vw - rem(62));
  --outside-mobile-frame: 0;

  background-color: clr(g-90);
  border-radius: rem(12);
  padding: rem(16) rem(16);
}

.accent {
  border: bdr(3, p-70);
}
