.recommendList {
  display: grid;

  @media bp(md) {
    grid-template-columns: none;
  }
}

.title{
  @media bp(md) {
    grid-column: auto;
  }
}
