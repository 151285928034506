.container {
  font-size: rem(12);
  color: clr(b/30);
  text-align: left;
  line-height: 1;
}

.code {
  color: clr(g-40);
}

.codeContent {
  color: clr(g-40);
}
