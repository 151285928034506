.container {
  display: grid;
  column-gap: rem(12);
  grid-template:
    'label label' auto
    'image name' auto
    'image description' auto
    'image bottomLine' 1fr / rem(48) 1fr;
}

.label {
  margin-bottom: rem(12);
  grid-area: label;
  font-size: rem(18);
  line-height: rem(28);
  letter-spacing: rem(-0.3);
  font-weight: 600;
}

.image {
  grid-area: image;
  width: rem(48);
  height: rem(48);
  border-radius: 100%;
}

.name {
  grid-area: name;
  font-size: rem(16);
  line-height: rem(24);
  font-weight: 600;
  letter-spacing: rem(-0.3);
}

.description {
  margin-top: rem(4);
  grid-area: description;
  font-size: rem(16);
  line-height: rem(24);
  font-weight: 500;
  letter-spacing: rem(-0.3);
  color: clr(g-25);
}

.descriptionCollapsed {
  @mixin lineClamp 2;
}

.bottomLine {
  margin-top: rem(12);
  grid-area: bottomLine;
  display: flex;
  column-gap: rem(8);
}

.socialIcon {
  width: rem(20);
  height: rem(20);
}
