.buttonHolder,
.button {
  @media bp(sm) {
    grid-row: span 3;
  }
}

.accordionDeal {
  grid-row: span 3;
  align-self: flex-start;
}