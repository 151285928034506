.container {
  padding: rem(10) 0;
  font-size: rem(10);
  line-height: rem(12);
  color: clr(g-60);
  font-weight: 500;
  text-align: center;

  @media bp(md) {
    font-size: rem(12);
    padding: rem(5) 0;
  }
}
