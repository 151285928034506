.container {
  display: grid;
  row-gap: rem(7);
  column-gap: rem(14);
  grid-template-columns: auto auto 1fr;
  justify-items: start;
  align-items: center;

  @media bp(lg) {
    grid-template-columns: 1fr auto auto;

    .title {
      grid-column: span 1;
    }

    .label {
      display: none;
    }
  }
}

.title {
  font-size: rem(24);
  font-weight: bold;
  line-height: rem(29);
  grid-column: span 3;
}

.label {
  padding: 0 rem(8);
  column-gap: rem(7);
  font-weight: 500;
}
